define("ember-vertical-timeline/components/vertical-timeline", ["exports", "ember-vertical-timeline/templates/components/vertical-timeline"], function (_exports, _verticalTimeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _verticalTimeline.default,
    tagName: 'section',
    classNames: 'vertical-timeline',
    animate: true
  });
});
define("@jftechnology/ember-keycloak-auth/templates/components/keycloak-session-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vTIYsj/I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-sm small\"],[8],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"minValidity\"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[24,[\"keycloakSession\",\"minValidity\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"onLoad\"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[24,[\"keycloakSession\",\"onLoad\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"responseMode\"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[24,[\"keycloakSession\",\"responseMode\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"flow\"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[24,[\"keycloakSession\",\"flow\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"checkLoginIframe\"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[24,[\"keycloakSession\",\"checkLoginIframe\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"checkLoginIframeInterval\"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[24,[\"keycloakSession\",\"checkLoginIframeInterval\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"idpHint\"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[24,[\"keycloakSession\",\"idpHint\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@jftechnology/ember-keycloak-auth/templates/components/keycloak-session-config.hbs"
    }
  });
});
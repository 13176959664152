define("ember-models-table/templates/components/models-table/row-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WsdWSZ+o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"td\",true],[11,\"colspan\",[24,[\"visibleProcessedColumns\",\"length\"]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"expandedRowComponent\",\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"themeInstance\"],[[24,[\"expandedRowComponent\"]],[24,[\"record\"]],[24,[\"visibleProcessedColumns\"]],[24,[\"index\"]],[24,[\"clickOnRow\"]],[24,[\"themeInstance\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"expandedRowComponent\"]]],[[\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"themeInstance\"],[[24,[\"record\"]],[24,[\"visibleProcessedColumns\"]],[24,[\"index\"]],[24,[\"clickOnRow\"]],[24,[\"themeInstance\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/row-expand.hbs"
    }
  });
});
define("ember-paper/templates/components/paper-card-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9pqKtaV0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"text\",\"avatar\"],[[28,\"component\",[\"paper-card-header-text\"],null],[28,\"component\",[\"paper-card-avatar\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-card-header.hbs"
    }
  });
});
define("ember-paper/components/paper-tab", ["exports", "ember-paper/templates/components/paper-tab", "ember-composability-tools", "ember-paper/mixins/focusable-mixin", "ember-invoke-action"], function (_exports, _paperTab, _emberComposabilityTools, _focusableMixin, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_emberComposabilityTools.ChildMixin, _focusableMixin.default, {
    layout: _paperTab.default,
    tagName: 'md-tab-item',
    classNames: ['md-tab'],
    classNameBindings: ['isSelected:md-active'],
    attributeBindings: ['isSelected:aria-selected', 'style', 'maybeHref:href'],
    // <a> tags have browser styles or are usually styled by the user
    // this makes sure that tab item still looks good with an anchor tag
    style: Ember.computed('href', function () {
      if (this.get('href')) {
        return Ember.String.htmlSafe('text-decoration: none; border: none;');
      } else {
        return undefined;
      }
    }),
    maybeHref: Ember.computed('href', 'disabled', function () {
      if (this.get('href') && !this.get('disabled')) {
        return this.get('href');
      } else {
        return undefined;
      }
    }),
    isSelected: Ember.computed('selected', 'value', function () {
      return this.get('selected') === this.get('value');
    }),
    init() {
      this._super(...arguments);
      if (this.get('href')) {
        this.set('tagName', 'a');
      }
    },
    // this method is called by the parent
    updateDimensions() {
      // this is the true current width
      // it is used to calculate the ink bar position & pagination offset
      this.setProperties({
        left: this.element.offsetLeft,
        width: this.element.offsetWidth
      });
    },
    click() {
      if (!this.get('disabled')) {
        (0, _emberInvokeAction.invokeAction)(this, 'onClick', ...arguments);
        (0, _emberInvokeAction.invokeAction)(this, 'onSelect', this);
      }
    }
  });
});
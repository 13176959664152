define("ember-composable-helpers/helpers/find-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.findBy = findBy;
  function findBy([byPath, value, array]) {
    if (Ember.isEmpty(byPath)) {
      return [];
    }
    return Ember.A(array).findBy(byPath, value);
  }
  var _default = _exports.default = Ember.Helper.helper(findBy);
});
define("ember-composable-helpers/helpers/entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.keys = keys;
  function keys([object]) {
    if (!object) {
      return object;
    }
    return Object.entries(object);
  }
  var _default = _exports.default = Ember.Helper.helper(keys);
});
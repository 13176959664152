define("ember-paper/components/paper-speed-dial-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'md-fab-trigger',
    click() {
      this.get('speedDial').toggle();
    },
    focusOut() {
      this.get('speedDial').close();
    }
  });
});
define("ember-models-table/templates/components/models-table/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Tpw8iDMc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"anyFilterUsed\",\"summary\",\"clearFilters\",\"firstIndex\",\"lastIndex\",\"recordsCount\"],[[24,[\"anyFilterUsed\"]],[24,[\"summary\"]],[24,[\"clearFilters\"]],[24,[\"firstIndex\"]],[24,[\"lastIndex\"]],[24,[\"recordsCount\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"form\",true],[11,\"class\",[24,[\"themeInstance\",\"form\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[24,[\"themeInstance\",\"formElementWrapper\"]]],[8],[0,\"\\n      \"],[7,\"label\",true],[11,\"for\",[22,\"inputId\"]],[8],[0,\"\\n        \"],[1,[22,\"summary\"],false],[0,\"\\n        \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"role\",\"button\"],[12,\"class\",[28,\"concat\",[\"clearFilters \",[28,\"unless\",[[24,[\"anyFilterUsed\"]],\"hidden\"],null]],null]],[3,\"action\",[[23,0,[]],\"clearFilters\"]],[8],[0,\"\\n          \"],[7,\"i\",true],[11,\"class\",[24,[\"themeInstance\",\"clearAllFiltersIcon\"]]],[8],[9],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"emt-sr-only\"],[8],[1,[24,[\"themeInstance\",\"messages\",\"clearAllFilters\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"],[0,\"      \"],[7,\"input\",true],[11,\"id\",[22,\"inputId\"]],[11,\"class\",[28,\"concat\",[[24,[\"themeInstance\",\"input\"]],\" invisible\"],null]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/summary.hbs"
    }
  });
});
define("ember-composable-helpers/helpers/take", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.take = take;
  function take([takeAmount, array]) {
    if (!array) {
      array = [];
    }
    return array.slice(0, takeAmount);
  }
  var _default = _exports.default = Ember.Helper.helper(take);
});
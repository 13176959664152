define("ember-paper/components/paper-select/eps-trigger/component", ["exports", "ember-paper/components/paper-select/eps-trigger/template", "@ember-decorators/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let PaperSelectEpsTrigger = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('placeholder', 'extra.label', 'select.selected'), _dec4 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class PaperSelectEpsTrigger extends Ember.Component {
    get isPlaceholder() {
      return (this.placeholder || this.extra.label) && !this.select.selected;
    }
    clear(e) {
      e.stopPropagation();
      this.select.actions.select(null);
      if (e.type === 'touchstart') {
        return false;
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "isPlaceholder", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "isPlaceholder"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clear", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "clear"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = PaperSelectEpsTrigger;
});
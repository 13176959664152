define("ember-paper/templates/components/paper-sidenav-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2IyVw04J",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"action\",[[23,0,[]],[24,[\"toggle\"]]],null]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-sidenav-toggle.hbs"
    }
  });
});
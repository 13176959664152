define("ember-paper/components/paper-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mJCnqqto",
    "block": "{\"symbols\":[\"@padding\",\"@scroll-y\",\"&attrs\",\"&default\"],\"statements\":[[7,\"md-content\",false],[12,\"class\",[29,[\"md-default-theme \",[28,\"if\",[[23,1,[]],\"md-padding\"],null]]]],[12,\"md-scroll-y\",[23,2,[]]],[13,3],[8],[0,\"\\n  \"],[14,4],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-content/template.hbs"
    }
  });
});
define("ember-composable-helpers/helpers/join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.join = join;
  function join([separator, array]) {
    if (!array) {
      array = [];
    }
    if (Ember.isArray(separator)) {
      array = separator;
      separator = ',';
    }
    return array.join(separator);
  }
  var _default = _exports.default = Ember.Helper.helper(join);
});
define("ember-power-calendar/templates/components/power-calendar/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "A+PFdcHU",
    "block": "{\"symbols\":[\"@calendar\",\"&default\"],\"statements\":[[7,\"nav\",true],[10,\"class\",\"ember-power-calendar-nav\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"actions\",\"changeCenter\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"ember-power-calendar-nav-control ember-power-calendar-nav-control--previous\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[24,[\"calendar\",\"actions\",\"moveCenter\"]],-1,[23,0,[\"unit\"]],[23,1,[]]],null]]],[8],[0,\"«\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ember-power-calendar-nav-title\"],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"      \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"power-calendar-format-date\",[[23,1,[\"center\"]],[23,0,[\"format\"]]],[[\"locale\"],[[23,1,[\"locale\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"calendar\",\"actions\",\"changeCenter\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"ember-power-calendar-nav-control ember-power-calendar-nav-control--next\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,1,[\"actions\",\"moveCenter\"]],1,[23,0,[\"unit\"]],[23,1,[]]],null]]],[8],[0,\"»\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-calendar/templates/components/power-calendar/nav.hbs"
    }
  });
});
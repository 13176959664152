define("ember-paper/templates/components/paper-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nmttfQ5p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"isValid\",\"isInvalid\",\"isTouched\",\"isInvalidAndTouched\",\"input\",\"submit-button\",\"select\",\"autocomplete\",\"onSubmit\"],[[24,[\"isValid\"]],[24,[\"isInvalid\"]],[24,[\"isTouched\"]],[24,[\"isInvalidAndTouched\"]],[28,\"component\",[[24,[\"inputComponent\"]]],[[\"parentComponent\",\"onValidityChange\"],[[23,0,[]],[28,\"action\",[[23,0,[]],\"onValidityChange\"],null]]]],[28,\"component\",[[24,[\"submitButtonComponent\"]]],[[\"type\"],[\"submit\"]]],[28,\"component\",[[24,[\"selectComponent\"]]],[[\"parentComponent\",\"onValidityChange\"],[[23,0,[]],[28,\"action\",[[23,0,[]],\"onValidityChange\"],null]]]],[28,\"component\",[[24,[\"autocompleteComponent\"]]],[[\"parentComponent\",\"onValidityChange\"],[[23,0,[]],[28,\"action\",[[23,0,[]],\"onValidityChange\"],null]]]],[28,\"action\",[[23,0,[]],\"onSubmit\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-form.hbs"
    }
  });
});
define("ember-power-select/components/power-select/options", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select/options"], function (_exports, _component, _options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const isTouchDevice = !!window && 'ontouchstart' in window;
  if (typeof FastBoot === 'undefined') {
    (function (ElementProto) {
      if (typeof ElementProto.matches !== 'function') {
        ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector;
      }
      if (typeof ElementProto.closest !== 'function') {
        ElementProto.closest = function closest(selector) {
          let element = this;
          while (element && element.nodeType === 1) {
            if (element.matches(selector)) {
              return element;
            }
            element = element.parentNode;
          }
          return null;
        };
      }
    })(window.Element.prototype);
  }
  let Options = _exports.default = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_options.default), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class Options extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "isTouchDevice", isTouchDevice);
    }
    addHandlers(element) {
      let role = element.getAttribute('role');
      if (role === 'group') {
        return;
      }
      let findOptionAndPerform = (action, e) => {
        let optionItem = e.target.closest('[data-option-index]');
        if (!optionItem) {
          return;
        }
        if (optionItem.closest('[aria-disabled=true]')) {
          return; // Abort if the item or an ancestor is disabled
        }
        let optionIndex = optionItem.getAttribute('data-option-index');
        action(this._optionFromIndex(optionIndex), e);
      };
      element.addEventListener('mouseup', e => findOptionAndPerform(this.select.actions.choose, e));
      if (this.highlightOnHover) {
        element.addEventListener('mouseover', e => findOptionAndPerform(this.select.actions.highlight, e));
      }
      if (this.isTouchDevice) {
        let touchMoveHandler = () => {
          this.hasMoved = true;
          if (element) {
            element.removeEventListener('touchmove', touchMoveHandler);
          }
        };
        // Add touch event handlers to detect taps
        element.addEventListener('touchstart', () => {
          element.addEventListener('touchmove', touchMoveHandler);
        });
        element.addEventListener('touchend', e => {
          let optionItem = e.target.closest('[data-option-index]');
          if (!optionItem) {
            return;
          }
          e.preventDefault();
          if (this.hasMoved) {
            this.hasMoved = false;
            return;
          }
          if (optionItem.closest('[aria-disabled=true]')) {
            return; // Abort if the item or an ancestor is disabled
          }
          let optionIndex = optionItem.getAttribute('data-option-index');
          this.select.actions.choose(this._optionFromIndex(optionIndex), e);
        });
      }
      if (role !== 'group') {
        this.select.actions.scrollTo(this.select.highlighted);
      }
    }
    _optionFromIndex(index) {
      let parts = index.split('.');
      let option = this.options[parseInt(parts[0], 10)];
      for (let i = 1; i < parts.length; i++) {
        option = option.options[parseInt(parts[i], 10)];
      }
      return option;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "addHandlers", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "addHandlers"), _class2.prototype)), _class2)) || _class) || _class);
});
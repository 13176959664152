define("ember-paper/templates/components/paper-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4dWxDVZB",
    "block": "{\"symbols\":[\"&default\",\"@iconButton\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"paper-ripple\",[],[[\"@fitRipple\",\"@center\",\"@dimBackground\"],[[23,2,[]],[23,2,[]],[28,\"not\",[[23,2,[]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-button.hbs"
    }
  });
});
define("ember-paper/templates/components/paper-card-title-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YTvWcSbh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"md-media-\",[22,\"size\"]]]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"class\",[29,[\"md-media-\",[22,\"size\"]]]],[11,\"src\",[22,\"src\"]],[11,\"alt\",[22,\"alt\"]],[11,\"title\",[22,\"title\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-card-title-media.hbs"
    }
  });
});
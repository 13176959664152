define("ember-paper/components/paper-ripple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cVqDzJI8",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"unbound\",[[23,0,[\"_parentFinder\"]]],null],false],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"class\",\"md-ripple-container\"],[3,\"did-insert\",[[23,0,[\"setupContainer\"]]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-ripple/template.hbs"
    }
  });
});
define("ember-models-table/templates/components/models-table/columns-hidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XYCyvQEb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"td\",true],[11,\"colspan\",[22,\"columnsCount\"]],[11,\"class\",[24,[\"themeInstance\",\"noDataCell\"]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"columnsCount\",\"themeInstance\"],[[24,[\"columnsCount\"]],[24,[\"themeInstance\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"html-safe\",[[24,[\"themeInstance\",\"messages\",\"allColumnsAreHidden\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/columns-hidden.hbs"
    }
  });
});
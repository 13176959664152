define("ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HVcFKi8f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"anyFilterUsed\",\"summary\",\"clearFilters\",\"firstIndex\",\"lastIndex\",\"recordsCount\"],[[24,[\"anyFilterUsed\"]],[24,[\"summary\"]],[24,[\"clearFilters\"]],[24,[\"firstIndex\"]],[24,[\"lastIndex\"]],[24,[\"recordsCount\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"bs-form\",null,[[\"formLayout\",\"model\",\"onSubmit\"],[\"inline\",[23,0,[]],[28,\"action\",[[23,0,[]],\"noop\"],null]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[1,[22,\"summary\"],false],[9],[0,\"\\n\"],[4,\"bs-button\",null,[[\"class\",\"disabled\",\"type\",\"onClick\",\"buttonType\"],[[28,\"concat\",[\"clearFilters border-0 \",[24,[\"themeInstance\",\"buttonLink\"]]],null],[28,\"unless\",[[24,[\"anyFilterUsed\"]],\"disabled\"],null],\"bytton\",[28,\"action\",[[23,0,[]],\"clearFilters\"],null],\"secondary\"]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[24,[\"themeInstance\",\"clearAllFiltersIcon\"]]],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"emt-sr-only\"],[8],[1,[24,[\"themeInstance\",\"messages\",\"clearAllFilters\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/summary.hbs"
    }
  });
});
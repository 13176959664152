define("ember-composable-helpers/helpers/sort-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(params) {
      // slice params to avoid mutating the provided params
      let sortProps = params.slice();
      let array = sortProps.pop();
      let [firstSortProp] = sortProps;
      if (Ember.typeOf(firstSortProp) === 'function' || Ember.isArray(firstSortProp)) {
        sortProps = firstSortProp;
      }

      // TODO: can we / should we use variables instead of computed properties?
      Ember.set(this, 'array', array);
      Ember.set(this, 'sortProps', sortProps);
      if (Ember.isEmpty(sortProps)) {
        Ember.defineProperty(this, 'content', []);
      }
      if (typeof sortProps === 'function') {
        Ember.defineProperty(this, 'content', Ember.computed.sort('array', sortProps));
      } else {
        Ember.defineProperty(this, 'content', Ember.computed.sort('array', 'sortProps'));
      }
      return this.content;
    }
  });
});
define("ember-composable-helpers/helpers/map-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mapBy = mapBy;
  function mapBy([byPath, array]) {
    if (Ember.isEmpty(byPath)) {
      return [];
    }
    if (!array) {
      array = [];
    }
    return array.map(item => Ember.get(item, byPath));
  }
  var _default = _exports.default = Ember.Helper.helper(mapBy);
});
define("ember-paper/templates/components/paper-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Gb2CH4c1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"md-tabs-wrapper\",true],[11,\"class\",[28,\"if\",[[24,[\"shouldStretch\"]],\"md-stretch-tabs\"],null]],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"shouldPaginate\"]]],null,{\"statements\":[[0,\"    \"],[7,\"md-prev-button\",true],[10,\"role\",\"button\"],[11,\"class\",[28,\"unless\",[[24,[\"canPageBack\"]],\"md-disabled\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"previousPage\"],null]],[8],[0,\"\\n      \"],[1,[28,\"paper-icon\",[\"keyboard-arrow-left\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"md-next-button\",true],[10,\"role\",\"button\"],[11,\"class\",[28,\"unless\",[[24,[\"canPageForward\"]],\"md-disabled\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"nextPage\"],null]],[8],[0,\"\\n      \"],[1,[28,\"paper-icon\",[\"keyboard-arrow-left\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"md-tabs-canvas\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"shouldPaginate\"]],\"md-paginated\"],null],\" \",[28,\"if\",[[24,[\"shouldCenter\"]],\"md-center-tabs\"],null]]]],[10,\"tabindex\",\"-1\"],[10,\"role\",\"tablist\"],[8],[0,\"\\n    \"],[7,\"md-pagination-wrapper\",true],[11,\"class\",[28,\"if\",[[24,[\"shouldCenter\"]],\"md-center-tabs\"],null]],[11,\"style\",[28,\"if\",[[24,[\"shouldPaginate\"]],[24,[\"paginationStyle\"]]],null]],[8],[0,\"\\n\\n      \"],[14,1,[[28,\"hash\",null,[[\"tab\"],[[28,\"component\",[\"paper-tab\"],[[\"noInk\",\"selected\",\"onSelect\"],[[24,[\"noInk\"]],[24,[\"selected\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]]]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"inkBar\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"paper-ink-bar\",null,[[\"movingRight\",\"left\",\"right\"],[[24,[\"movingRight\"]],[24,[\"inkBar\",\"left\"]],[24,[\"inkBar\",\"right\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-tabs.hbs"
    }
  });
});
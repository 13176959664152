define("ember-composable-helpers/helpers/from-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function fromEntries([entries]) {
    if (!entries) {
      return entries;
    }
    return Object.fromEntries(entries);
  });
});
define("liquid-fire/components/liquid-spacer", ["exports", "liquid-fire/components/liquid-measured", "liquid-fire/growable", "liquid-fire/templates/components/liquid-spacer"], function (_exports, _liquidMeasured, _growable, _liquidSpacer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_growable.default, {
    layout: _liquidSpacer.default,
    enabled: true,
    didInsertElement() {
      let elt = Ember.$(this.element);
      let child = elt.find('> div');
      let measurements = this.myMeasurements((0, _liquidMeasured.measure)(child));
      this.element.style.overflow = 'hidden';
      if (this.get('growWidth')) {
        elt.outerWidth(measurements.width);
      }
      if (this.get('growHeight')) {
        elt.outerHeight(measurements.height);
      }
    },
    sizeChanged(measurements) {
      if (!this.get('enabled')) {
        return;
      }
      if (!this.element) {
        return;
      }
      let want = this.myMeasurements(measurements);
      let elt = Ember.$(this.element);
      let have = (0, _liquidMeasured.measure)(elt);
      this.animateGrowth(elt, have, want);
    },
    // given our child's outerWidth & outerHeight, figure out what our
    // outerWidth & outerHeight should be.
    myMeasurements(childMeasurements) {
      let elt = Ember.$(this.element);
      return {
        width: childMeasurements.width + sumCSS(elt, padding('width')) + sumCSS(elt, border('width')),
        height: childMeasurements.height + sumCSS(elt, padding('height')) + sumCSS(elt, border('height'))
      };
    }
  });
  function sides(dimension) {
    return dimension === 'width' ? ['Left', 'Right'] : ['Top', 'Bottom'];
  }
  function padding(dimension) {
    let s = sides(dimension);
    return ['padding' + s[0], 'padding' + s[1]];
  }
  function border(dimension) {
    let s = sides(dimension);
    return ['border' + s[0] + 'Width', 'border' + s[1] + 'Width'];
  }
  function sumCSS(elt, fields) {
    let accum = 0;
    for (let i = 0; i < fields.length; i++) {
      let num = parseFloat(elt.css(fields[i]), 10);
      if (!isNaN(num)) {
        accum += num;
      }
    }
    return accum;
  }
});
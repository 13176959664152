define("ember-composable-helpers/helpers/reduce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reduce = reduce;
  function reduce([callback, initialValue, array]) {
    if (Ember.isEmpty(callback)) {
      return [];
    }
    return array.reduce(callback, initialValue);
  }
  var _default = _exports.default = Ember.Helper.helper(reduce);
});
define("ember-models-table/helpers/stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringify = stringify;
  function stringify([val] /*, hash*/) {
    return JSON.stringify(val);
  }
  var _default = _exports.default = Ember.Helper.helper(stringify);
});
define("ember-models-table/templates/components/models-table/cell-edit-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZoQl232t",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isEditRow\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"class\",[24,[\"themeInstance\",\"cancel-row-button\"]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"cancelClicked\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[22,\"cancelButtonLabel\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",true],[11,\"class\",[24,[\"themeInstance\",\"save-row-button\"]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"saveClicked\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[22,\"saveButtonLabel\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"class\",[24,[\"themeInstance\",\"edit-row-button\"]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"editClicked\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[22,\"editButtonLabel\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell-edit-toggle.hbs"
    }
  });
});
define("ember-models-table/constants/sort-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: 'none'
  };
});
define("ember-paper/templates/components/paper-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9ATV29qD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"md-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-icon\"],[8],[9],[0,\"\\n  \"],[5,\"paper-ripple\",[],[[\"@center\",\"@fitRipple\"],[true,true]]],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"md-label\"],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"id\",[22,\"labelId\"]],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"md-label\"],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"id\",[22,\"labelId\"]],[8],[0,\"\\n      \"],[1,[22,\"label\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-checkbox.hbs"
    }
  });
});
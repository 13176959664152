define("ember-models-table/templates/components/models-table/table-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PsvzpIYA",
    "block": "{\"symbols\":[\"tf\",\"column\",\"&default\"],\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"goToPage\",\"clearFilters\",\"expandRow\",\"collapseRow\",\"expandAllRows\",\"collapseAllRows\",\"themeInstance\",\"selectedItems\",\"expandedItems\",\"visibleProcessedColumns\",\"data\"],[[24,[\"goToPage\"]],[24,[\"clearFilters\"]],[24,[\"expandRow\"]],[24,[\"collapseRow\"]],[24,[\"expandAllRows\"]],[24,[\"collapseAllRows\"]],[24,[\"themeInstance\"]],[24,[\"selectedItems\"]],[24,[\"expandedItems\"]],[24,[\"visibleProcessedColumns\"]],[24,[\"data\"]]]]]],null,{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"    \"],[14,3,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"visibleProcessedColumns\"]]],null,{\"statements\":[[4,\"if\",[[23,2,[\"componentForFooterCell\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"component\",[[23,2,[\"componentForFooterCell\"]]],[[\"selectedItems\",\"expandedItems\",\"data\",\"mappedSelectedItems\",\"mappedExpandedItems\",\"mappedData\"],[[24,[\"selectedItems\"]],[24,[\"expandedItems\"]],[24,[\"data\"]],[28,\"if\",[[23,2,[\"propertyName\"]],[28,\"map-by\",[[23,2,[\"propertyName\"]],[24,[\"selectedItems\"]]],null]],null],[28,\"if\",[[23,2,[\"propertyName\"]],[28,\"map-by\",[[23,2,[\"propertyName\"]],[24,[\"expandedItems\"]]],null]],null],[28,\"if\",[[23,2,[\"propertyName\"]],[28,\"map-by\",[[23,2,[\"propertyName\"]],[24,[\"data\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"td\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/table-footer.hbs"
    }
  });
});
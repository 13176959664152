define("ember-paper/components/paper-sidenav-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class PaperSidenavContainer
   * @extends Ember.Component
   */
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['flex', 'layout-row'],
    attributeBindings: ['style'],
    style: Ember.String.htmlSafe('overflow: hidden')
  });
});
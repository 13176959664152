define("ember-data-change-tracker/utilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relationshipKnownState = _exports.relationShipTransform = _exports.modelTransform = _exports.isEmpty = _exports.didSerializedModelChange = _exports.didModelsChange = _exports.didModelChange = void 0;
  const modelTransform = function (model, polymorphic) {
    if (polymorphic) {
      return {
        id: model.id,
        type: model.modelName || model.constructor.modelName
      };
    }
    return model.id;
  };
  _exports.modelTransform = modelTransform;
  const relationShipTransform = _exports.relationShipTransform = {
    belongsTo: {
      serialize(model, key, options) {
        let relationship = model.belongsTo(key).belongsToRelationship;
        let value = relationship.hasOwnProperty('inverseRecordData') ? relationship.inverseRecordData : relationship.canonicalState;
        return value && modelTransform(value, options.polymorphic);
      },
      deserialize() {}
    },
    hasMany: {
      serialize(model, key, options) {
        let relationship = model.hasMany(key).hasManyRelationship;
        let value = relationship.currentState;
        return value && value.map(item => modelTransform(item, options.polymorphic));
      },
      deserialize() {}
    }
  };
  const relationshipKnownState = _exports.relationshipKnownState = {
    belongsTo: {
      isKnown(model, key) {
        let belongsTo = model.belongsTo(key);
        let relationship = belongsTo.belongsToRelationship;
        return !relationship.relationshipIsStale;
      }
    },
    hasMany: {
      isKnown(model, key) {
        let hasMany = model.hasMany(key);
        let relationship = hasMany.hasManyRelationship;
        return !relationship.relationshipIsStale;
      }
    }
  };
  const isEmpty = function (value) {
    if (Ember.typeOf(value) === 'object') {
      return Object.keys(value).length === 0;
    }
    return Ember.isEmpty(value);
  };
  _exports.isEmpty = isEmpty;
  const didSerializedModelChange = function (one, other, polymorphic) {
    if (polymorphic) {
      return one.id !== other.id || one.type !== other.type;
    }
    return one !== other;
  };
  _exports.didSerializedModelChange = didSerializedModelChange;
  const didModelsChange = function (one, other, polymorphic) {
    if (isEmpty(one) && isEmpty(other)) {
      return false;
    }
    if ((one && one.length) !== (other && other.length)) {
      return true;
    }
    for (let i = 0, len = one.length; i < len; i++) {
      if (didSerializedModelChange(one[i], other[i], polymorphic)) {
        return true;
      }
    }
    return false;
  };
  _exports.didModelsChange = didModelsChange;
  const didModelChange = function (one, other, polymorphic) {
    if (isEmpty(one) && isEmpty(other)) {
      return false;
    }
    if (!one && other || one && !other) {
      return true;
    }
    return didSerializedModelChange(one, other, polymorphic);
  };
  _exports.didModelChange = didModelChange;
});
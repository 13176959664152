define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "ad": "ad",
    "advertiser": "advertiser",
    "agency": "agency",
    "campaign": "campaign",
    "english": "english",
    "malay": "malay",
    "media": "media"
  }], ["ms-my", {
    "ad": "iklan",
    "advertiser": "pengiklan",
    "agency": "agensi",
    "campaign": "kempen",
    "english": "bahasa inggeris",
    "malay": "bahasa malaysia",
    "media": "media"
  }]];
});
define("liquid-fire/templates/components/liquid-bind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FPk9niYo",
    "block": "{\"symbols\":[\"container\",\"version\",\"version\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"containerless\"]]],null,{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\",\"class\"],[[24,[\"value\"]],[24,[\"use\"]],[24,[\"rules\"]],[24,[\"forwardMatchContext\"]],[24,[\"versionEquality\"]],true,[24,[\"class\"]]]],{\"statements\":[[4,\"if\",[[25,4]],null,{\"statements\":[[14,4,[[23,3,[]]]]],\"parameters\":[]},{\"statements\":[[1,[23,3,[]],false]],\"parameters\":[]}]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"liquid-container\",null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[24,[\"containerId\"]],[24,[\"class\"]],[24,[\"growDuration\"]],[24,[\"growPixelsPerSecond\"]],[24,[\"growEasing\"]],[24,[\"shrinkDelay\"]],[24,[\"growDelay\"]],[24,[\"enableGrowth\"]]]],{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"notify\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\"],[[24,[\"value\"]],[23,1,[]],[24,[\"use\"]],[24,[\"rules\"]],[24,[\"forwardMatchContext\"]],[24,[\"versionEquality\"]],true]],{\"statements\":[[4,\"if\",[[25,4]],null,{\"statements\":[[14,4,[[23,2,[]]]]],\"parameters\":[]},{\"statements\":[[1,[23,2,[]],false]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-bind.hbs"
    }
  });
});
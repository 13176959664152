define("ember-composable-helpers/helpers/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.groupBy = groupBy;
  function groupBy([byPath, array]) {
    let groups = {};
    array.forEach(item => {
      let groupName = Ember.get(item, byPath);
      let group = groups[groupName];
      if (!Array.isArray(group)) {
        group = [];
        groups[groupName] = group;
      }
      group.push(item);
    });
    return groups;
  }
  var _default = _exports.default = Ember.Helper.helper(groupBy);
});
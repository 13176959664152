define("ember-paper/templates/components/paper-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fo2d7K/C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"md-text\"],[8],[14,1],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-option.hbs"
    }
  });
});
define("ember-paper/components/paper-ripple/component", ["exports", "ember-paper/components/paper-ripple/template", "@ember-decorators/component", "ember-paper/utils/browser-features", "ember-css-transitions/mixins/transition-mixin"], function (_exports, _template, _component, _browserFeatures, _transitionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const DURATION = 400;
  let PaperRipple = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('noink', 'rippleInkColor'), _dec4 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class PaperRipple extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_parentFinder", self.document ? self.document.createTextNode('') : '');
      _defineProperty(this, "center", false);
      _defineProperty(this, "dimBackground", false);
      _defineProperty(this, "fitRipple", false);
      _defineProperty(this, "colorElement", false);
      _defineProperty(this, "noink", false);
      _defineProperty(this, "ripples", []);
      _defineProperty(this, "timeout", null);
      _defineProperty(this, "lastRipple", null);
      _defineProperty(this, "mousedown", false);
    } // Stores a reference to the most-recent ripple timeout
    get rippleInk() {
      if (this.noink) {
        return false;
      }
      if (this.rippleInkColor) {
        return this.rippleInkColor;
      }
      return '';
    }
    setupContainer(container) {
      this.parentNode = this._parentFinder.parentNode;
      this.rippleElement = this.parentNode;
      this._container = container;
      this.rippleElement.classList.add('md-ink-ripple');
      this.bindEvents();
    }
    clearTimeout() {
      if (this.timeout) {
        Ember.run.cancel(this.timeout);
        this.timeout = null;
      }
    }
    bindEvents() {
      let re = this.rippleElement;
      re.addEventListener('mousedown', Ember.run.bind(this, this.handleMousedown));
      re.addEventListener('mouseup', Ember.run.bind(this, this.handleMouseup));
      re.addEventListener('mouseleave', Ember.run.bind(this, this.handleMouseup));
      let options = _browserFeatures.supportsPassiveEventListeners ? {
        passive: true
      } : false;
      re.addEventListener('touchend', Ember.run.bind(this, this.handleMouseup), options);
      re.addEventListener('touchmove', Ember.run.bind(this, this.handleTouchmove), options);
    }
    handleMousedown(event) {
      if (this.mousedown) {
        return;
      }

      // When jQuery is loaded, we have to get the original event
      if (event.hasOwnProperty('originalEvent')) {
        event = event.originalEvent;
      }
      this.mousedown = true;
      if (this.center) {
        this.createRipple(this._container.clientWidth / 2, this._container.clientWidth / 2);
      } else {
        // We need to calculate the relative coordinates if the target is a sublayer of the ripple element
        if (event.srcElement !== this.rippleElement) {
          let layerRect = this.rippleElement.getBoundingClientRect();
          let layerX = event.clientX - layerRect.left;
          let layerY = event.clientY - layerRect.top;
          this.createRipple(layerX, layerY);
        } else {
          this.createRipple(event.offsetX, event.offsetY);
        }
      }
    }
    async autoCleanup(cleanupFn) {
      if (this.mousedown || this.lastRipple) {
        this.mousedown = false;
        await (0, _transitionMixin.nextTick)();
        cleanupFn.bind(this)();
      }
    }
    color(value) {
      /*
       * Finds the color element and returns its text color for use as default ripple color
       * @returns {string}
       */
      let getElementColor = () => {
        let items = this.colorElement ? this.colorElement : [];
        let elem = items.length ? items[0] : this.rippleElement;
        return elem ? window.getComputedStyle(elem).color : 'rgb(0,0,0)';
      };

      // If assigning a color value, apply it to background and the ripple color
      if (typeof value !== 'undefined') {
        this._color = this._parseColor(value);
      }

      // If color lookup, use assigned, defined, or inherited
      return this._color || this._parseColor(this.rippleInk) || this._parseColor(getElementColor());
    }
    _parseColor(color, multiplier) {
      multiplier = multiplier || 1;
      if (!color) {
        return;
      }
      if (color.indexOf('rgba') === 0) {
        return color.replace(/\d?\.?\d*\s*\)\s*$/, `${(0.1 * multiplier).toString()})`);
      }
      if (color.indexOf('rgb') === 0) {
        return rgbToRGBA(color);
      }
      if (color.indexOf('#') === 0) {
        return hexToRGBA(color);
      }

      /*
       * Converts hex value to RGBA string
       * @param color {string}
       * @returns {string}
       */
      function hexToRGBA(color) {
        let hex = color[0] === '#' ? color.substr(1) : color;
        let dig = hex.length / 3;
        let red = hex.substr(0, dig);
        let green = hex.substr(dig, dig);
        let blue = hex.substr(dig * 2);
        if (dig === 1) {
          red += red;
          green += green;
          blue += blue;
        }
        return `rgba(${parseInt(red, 16)}, ${parseInt(green, 16)}, ${parseInt(blue, 16)}, 0.1)`;
      }

      /*
       * Converts an RGB color to RGBA
       * @param color {string}
       * @returns {string}
       */
      function rgbToRGBA(color) {
        return color.replace(')', ', 0.1)').replace('(', 'a(');
      }
    }
    handleMouseup() {
      this.autoCleanup(this.clearRipples);
    }
    handleTouchmove() {
      this.autoCleanup(this.deleteRipples);
    }
    deleteRipples() {
      for (let i = 0; i < this.ripples.length; i++) {
        this.ripples[i].remove();
      }
    }
    clearRipples() {
      for (let i = 0; i < this.ripples.length; i++) {
        this.fadeInComplete(this.ripples[i]);
      }
    }
    isRippleAllowed() {
      let element = this.rippleElement;
      do {
        if (!element.tagName || element.tagName === 'BODY') {
          break;
        }
        if (element && typeof element.hasAttribute === 'function') {
          if (element.hasAttribute('disabled')) {
            return false;
          }
          if (this.rippleInk === false) {
            return false;
          }
        }
        element = element.parentNode;
      } while (element);
      return true;
    }
    async createRipple(left, top) {
      if (!this.isRippleAllowed()) {
        return;
      }
      let ripple = document.createElement('div');
      ripple.classList.add('md-ripple');
      let width = this.rippleElement.clientWidth;
      let height = this.rippleElement.clientHeight;
      let x = Math.max(Math.abs(width - left), left) * 2;
      let y = Math.max(Math.abs(height - top), top) * 2;
      let size = getSize(this.get('fitRipple'), x, y);
      let color = this.color();
      let rippleCss = `
      left: ${left}px;
      top: ${top}px;
      background: 'black';
      width: ${size}px;
      height: ${size}px;
      background-color: ${rgbaToRGB(color)};
      border-color: ${rgbaToRGB(color)}
    `;
      ripple.style.cssText = rippleCss;
      this.lastRipple = ripple;

      // we only want one timeout to be running at a time
      this.clearTimeout();
      this.timeout = Ember.run.later(() => {
        this.clearTimeout();
        if (!this.mousedown) {
          this.fadeInComplete(ripple);
        }
      }, DURATION * 0.35);
      if (this.dimBackground) {
        this._container.style.cssText = `background-color: ${color}`;
      }
      this._container.appendChild(ripple);
      this.ripples.push(ripple);
      ripple.classList.add('md-ripple-placed');
      await (0, _transitionMixin.nextTick)();
      ripple.classList.add('md-ripple-scaled', 'md-ripple-active');
      Ember.run.later(() => {
        this.clearRipples();
      }, DURATION);
      function rgbaToRGB(color) {
        return color ? color.replace('rgba', 'rgb').replace(/,[^),]+\)/, ')') : 'rgb(0,0,0)';
      }
      function getSize(fit, x, y) {
        return fit ? Math.max(x, y) : Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
      }
    }
    fadeInComplete(ripple) {
      if (this.lastRipple === ripple) {
        if (!this.timeout && !this.mousedown) {
          this.removeRipple(ripple);
        }
      } else {
        this.removeRipple(ripple);
      }
    }
    removeRipple(ripple) {
      let index = this.ripples.indexOf(ripple);
      if (index < 0) {
        return;
      }
      this.ripples.splice(this.ripples.indexOf(ripple), 1);
      ripple.classList.remove('md-ripple-active');
      ripple.classList.add('md-ripple-remove');
      if (this.ripples.length === 0) {
        this._container.style.cssText = 'backgroundColor: \'\'';
      }

      // use a 2-second timeout in order to allow for the animation to finish
      // we don't actually care how long the animation takes
      Ember.run.later(() => {
        this.fadeOutComplete(ripple);
      }, DURATION);
    }
    fadeOutComplete(ripple) {
      ripple.parentNode.removeChild(ripple);
      this.lastRipple = null;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "rippleInk", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "rippleInk"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setupContainer", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "setupContainer"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = PaperRipple;
});
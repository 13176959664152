define("ember-paper/components/paper-card-title-media", ["exports", "ember-paper/templates/components/paper-card-title-media"], function (_exports, _paperCardTitleMedia) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class PaperCardTitleMedia
   * @extends Ember.Component
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _paperCardTitleMedia.default,
    tagName: 'md-card-title-media',
    size: 'md'
  });
});
define("@html-next/vertical-collection/-debug/edge-visualization/debug-mixin", ["exports", "@html-next/vertical-collection/-debug/edge-visualization/visualization", "@html-next/vertical-collection/-private", "@html-next/vertical-collection/-debug/utils/validate-style"], function (_exports, _visualization, _private, _validateStyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    debugVis: false,
    debugCSS: false,
    __visualization: null,
    init() {
      this._super(...arguments);
      this._radar._debugDidUpdate = () => {
        this.updateVisualization();
        this.detectIssuesWithCSS();
      };
    },
    detectIssuesWithCSS() {
      if (this.get('debugCSS') === false) {
        return;
      }
      let radar = this._radar;
      let styles;

      // check telescope
      if (radar.scrollContainer !== _private.ViewportContainer) {
        styles = window.getComputedStyle(radar.scrollContainer);
      } else {
        styles = window.getComputedStyle(document.body);
      }
      (true && !((0, _validateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex'])) && Ember.assert(`scrollContainer cannot be inline.`, (0, _validateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex'])));
      (true && !((0, _validateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute'])) && Ember.assert(`scrollContainer must define position`, (0, _validateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute'])));
      (true && !((0, _validateStyle.hasStyleWithNonZeroValue)(styles, 'height') || (0, _validateStyle.hasStyleWithNonZeroValue)(styles, 'max-height')) && Ember.assert(`scrollContainer must define height or max-height`, (0, _validateStyle.hasStyleWithNonZeroValue)(styles, 'height') || (0, _validateStyle.hasStyleWithNonZeroValue)(styles, 'max-height'))); // conditional perf check for non-body scrolling
      if (radar.scrollContainer !== _private.ViewportContainer) {
        (true && !((0, _validateStyle.hasStyleValue)(styles, 'overflow-y', 'scroll') || (0, _validateStyle.hasStyleValue)(styles, 'overflow', 'scroll')) && Ember.assert(`scrollContainer must define overflow-y`, (0, _validateStyle.hasStyleValue)(styles, 'overflow-y', 'scroll') || (0, _validateStyle.hasStyleValue)(styles, 'overflow', 'scroll')));
      }

      // check itemContainer
      styles = window.getComputedStyle(radar.itemContainer);
      (true && !((0, _validateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex'])) && Ember.assert(`itemContainer cannot be inline.`, (0, _validateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex'])));
      (true && !((0, _validateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute'])) && Ember.assert(`itemContainer must define position`, (0, _validateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute']))); // check item defaults
      (true && !(this.get('items.length')) && Ember.assert(`You must supply at least one item to the collection to debug it's CSS.`, this.get('items.length')));
      let element = radar._itemContainer.firstElementChild;
      styles = window.getComputedStyle(element);
      (true && !((0, _validateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex'])) && Ember.assert(`Item cannot be inline.`, (0, _validateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex'])));
      (true && !((0, _validateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute'])) && Ember.assert(`Item must define position`, (0, _validateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute'])));
    },
    updateVisualization() {
      if (this.get('debugVis') === false) {
        if (this.__visualization !== null) {
          console.info('tearing down existing visualization'); // eslint-disable-line no-console
          this.__visualization.destroy();
          this.__visualization = null;
        }
        return;
      }
      if (this.__visualization === null) {
        this.__visualization = new _visualization.default(this._radar);
      }
      this.__visualization.render();
    },
    willDestroy() {
      this._super();
      if (this.__visualization) {
        console.info('destroying visualization'); // eslint-disable-line no-console
        this.__visualization.destroy();
        this.__visualization = null;
      }
    }
  });
});
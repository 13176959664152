define("ember-star-rating/templates/components/star-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MaxW7Hzy",
    "block": "{\"symbols\":[\"star\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"stars\"]]],null,{\"statements\":[[0,\"  \"],[7,\"svg\",true],[11,\"width\",[22,\"width\"]],[11,\"height\",[22,\"height\"]],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",[22,\"viewBox\"]],[8],[0,\"\\n    \"],[7,\"defs\",true],[8],[0,\"\\n      \"],[7,\"linearGradient\",true],[11,\"id\",[29,[[22,\"elementId\"],\"-star-\",[23,2,[]]]]],[10,\"x1\",\"0%\"],[10,\"y1\",\"0%\"],[10,\"x2\",\"100%\"],[10,\"y2\",\"0%\"],[8],[0,\"\\n        \"],[7,\"stop\",true],[10,\"class\",\"star-rating-filled\"],[10,\"offset\",\"0%\"],[11,\"stop-color\",[22,\"fillColor\"]],[8],[9],[0,\"\\n        \"],[7,\"stop\",true],[10,\"class\",\"star-rating-base\"],[10,\"offset\",\"0%\"],[11,\"stop-color\",[22,\"baseColor\"]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"path\",true],[11,\"fill\",[29,[\"url(#\",[22,\"elementId\"],\"-star-\",[23,2,[]],\")\"]]],[11,\"d\",[22,\"svgPath\"]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-star-rating/templates/components/star-rating.hbs"
    }
  });
});
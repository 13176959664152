define("ember-paper/components/paper-card-media", ["exports", "ember-paper/templates/components/paper-card-media"], function (_exports, _paperCardMedia) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class PaperCardMedia
   * @extends Ember.Component
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _paperCardMedia.default,
    tagName: '',
    size: 'md'
  });
});
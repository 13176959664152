define("ember-composable-helpers/helpers/flatten", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.flatten = flatten;
  function flatten(array) {
    if (!Ember.isArray(array)) {
      return array;
    }
    return array.reduce((flattened, el) => {
      return flattened.concat(flatten(el));
    }, []);
  }
  var _default = _exports.default = Ember.Helper.helper(function ([array]) {
    return flatten(array);
  });
});
define("ember-paper/components/paper-autocomplete/ebd-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "R9ifoKmE",
    "block": "{\"symbols\":[\"@dropdown\",\"@required\",\"@label\",\"@eventType\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\",\"&attrs\",\"@disabled\",\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"shouldShowLabel\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"for\",[29,[\"ember-basic-dropdown-trigger-\",[23,1,[\"uniqueId\"]]]]],[11,\"class\",[28,\"if\",[[23,2,[]],\"md-required\"],null]],[8],[0,\"\\n    \"],[1,[23,3,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"basic-dropdown-trigger\",[[13,8],[12,\"md-floating-label\",[28,\"if\",[[23,3,[]],[23,3,[]]],null]],[12,\"class\",[28,\"if\",[[23,3,[]],\"md-floating-label\"],null]],[12,\"id\",[29,[\"ember-basic-dropdown-trigger-\",[23,1,[\"uniqueId\"]]]]],[12,\"disabled\",[23,9,[]]],[12,\"tabindex\",\"-1\"]],[[\"@htmlTag\",\"@eventType\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\"],[\"md-autocomplete\",[23,4,[]],[23,1,[]],[23,5,[]],[23,6,[]],[23,7,[]]]],{\"statements\":[[0,\"\\n  \"],[14,10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-autocomplete/ebd-trigger/template.hbs"
    }
  });
});
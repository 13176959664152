define("ember-models-table/components/models-table/themes/semanticui/pagination-simple", ["exports", "ember-models-table/components/models-table/pagination-simple", "ember-models-table/templates/components/models-table/themes/semanticui/pagination-simple"], function (_exports, _paginationSimple, _paginationSimple2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _paginationSimple.default.extend({
    layout: _paginationSimple2.default
  });
});
define("ember-paper/templates/components/paper-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ljuoyecZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"renderTooltip\"]]],null,{\"statements\":[[4,\"in-element\",[[24,[\"destinationEl\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"md-panel-outer-wrapper md-panel-is-showing\"],[11,\"style\",[22,\"containerStyle\"]],[8],[0,\"\\n\"],[4,\"paper-tooltip-inner\",null,[[\"class\",\"position\",\"anchorElement\",\"hide\"],[[24,[\"class\"]],[24,[\"position\"]],[24,[\"anchorElement\"]],[24,[\"hideTooltip\"]]]],{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-tooltip.hbs"
    }
  });
});
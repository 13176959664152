define("ember-data-change-tracker/initializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initializer = initializer;
  /* global require */
  function initializer() {
    require("ember-data-change-tracker/model-ext");
  }
});
define("ember-google-charts/utils/merge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = merge;
  /* http://youmightnotneedjquery.com/#extend */

  function merge(out) {
    out = out || {};
    for (let i = 1; i < arguments.length; i++) {
      if (!arguments[i]) continue;
      for (let key in arguments[i]) {
        if (Object.prototype.hasOwnProperty.call(arguments[i], key)) {
          out[key] = arguments[i][key];
        }
      }
    }
    return out;
  }
});
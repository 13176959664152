define("ember-paper/templates/components/paper-sidenav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6MX+BU0u",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"closed\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"paper-backdrop\",null,[[\"opaque\",\"class\",\"onClick\"],[true,\"md-sidenav-backdrop\",[28,\"action\",[[23,0,[]],\"onBackdropTap\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-sidenav-inner\",null,[[\"class\",\"name\",\"position\",\"lockedOpen\",\"closed\",\"closeOnClick\",\"onToggle\"],[[24,[\"class\"]],[24,[\"name\"]],[24,[\"position\"]],[24,[\"lockedOpen\"]],[24,[\"closed\"]],[24,[\"closeOnClick\"]],[28,\"action\",[[23,0,[]],\"onToggle\"],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-sidenav.hbs"
    }
  });
});
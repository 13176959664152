define("ember-in-element-polyfill/helpers/-clear-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function clearElement([element] /*, hash*/) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
    return element;
  });
});
define("ember-models-table/helpers/not-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notEq = notEq;
  function notEq([v1, v2] /*, hash*/) {
    return v1 !== v2;
  }
  var _default = _exports.default = Ember.Helper.helper(notEq);
});
define("ember-google-charts/components/line-chart", ["exports", "ember-google-charts/components/google-chart"], function (_exports, _googleChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _googleChart.default.extend({
    design: 'material',
    type: 'line'
  });
});
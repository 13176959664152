define("ember-paper/components/paper-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "n0EEgebH",
    "block": "{\"symbols\":[\"dd\",\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[5,\"basic-dropdown\",[[13,3]],[[\"@disabled\",\"@triggerComponent\",\"@contentComponent\",\"@calculatePosition\",\"@onClose\",\"@onOpen\"],[[23,2,[]],\"paper-menu/trigger\",\"paper-menu/content\",[23,0,[\"calculatePosition\"]],[23,0,[\"close\"]],[23,0,[\"open\"]]]],{\"statements\":[[0,\"\\n  \"],[14,4,[[28,\"hash\",null,[[\"trigger\",\"content\"],[[23,1,[\"Trigger\"]],[23,1,[\"Content\"]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-menu/template.hbs"
    }
  });
});
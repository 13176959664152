define("ember-paper/templates/components/paper-speed-dial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PCMQ8hBJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"trigger\",\"actions\"],[[28,\"component\",[\"paper-speed-dial-trigger\"],[[\"speedDial\"],[[23,0,[]]]]],[28,\"component\",[\"paper-speed-dial-actions\"],[[\"speedDial\"],[[23,0,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-speed-dial.hbs"
    }
  });
});
define("liquid-fire/templates/components/get-outlet-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qjwdqBoN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"-get-dynamic-var\",[\"outletState\"],null]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/get-outlet-state.hbs"
    }
  });
});
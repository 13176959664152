define("ember-paper/components/paper-select/ebd-content/component", ["exports", "ember-paper/components/paper-select/ebd-content/template", "@ember-decorators/component", "ember-css-transitions/mixins/transition-mixin", "ember-paper/utils/key-constants", "ember-power-select/utils/group-utils"], function (_exports, _template, _component, _transitionMixin, _keyConstants, _groupUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function waitForAnimations(element, callback) {
    let computedStyle = window.getComputedStyle(element);
    if (computedStyle.transitionDuration && computedStyle.transitionDuration !== '0s') {
      let eventCallback = function () {
        element.removeEventListener('transitionend', eventCallback);
        callback();
      };
      element.addEventListener('transitionend', eventCallback);
    } else if (computedStyle.animationName !== 'none' && computedStyle.animationPlayState === 'running') {
      let eventCallback = function () {
        element.removeEventListener('animationend', eventCallback);
        callback();
      };
      element.addEventListener('animationend', eventCallback);
    } else {
      callback();
    }
  }
  let PaperSelectEbdContent = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('otherStyles', 'isActive'), _dec4 = Ember.computed('destination'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class PaperSelectEbdContent extends Ember.Component {
    get customStyles() {
      if (this.isActive) {
        return {};
      } else {
        return this.otherStyles;
      }
    }
    get destinationElement() {
      return document.getElementById(this.destination);
    }
    animateIn(dropdownElement) {
      Ember.run.next(() => {
        Ember.run.scheduleOnce('afterRender', this, this.afterAnimateIn, dropdownElement);
      });
    }
    afterAnimateIn(dropdownElement) {
      this.dropdown.actions.reposition();
      this.set('isActive', true);
      this.focusItem(dropdownElement);
    }
    async animateOut(dropdownElement) {
      let parentElement = this.renderInPlace ? dropdownElement.parentElement.parentElement : dropdownElement.parentElement;
      let clone = dropdownElement.cloneNode(true);
      clone.id = `${clone.id}--clone`;
      parentElement.appendChild(clone);
      clone.children[0].children[0].scrollTop = dropdownElement.children[0].children[0].scrollTop;
      await (0, _transitionMixin.nextTick)();
      if (!this.isDestroyed) {
        this.set('isActive', false);
        clone.classList.add('md-leave');
        waitForAnimations(clone, function () {
          clone.classList.remove('md-active');
          parentElement.removeChild(clone);
        });
      } else {
        parentElement.removeChild(clone);
      }
    }
    focusItem(element) {
      let focusTarget = element.querySelector('md-option[aria-selected="true"]');

      // default to first not disabled option
      if (!focusTarget) {
        focusTarget = element.querySelector('md-option:not([aria-disabled="true"])');
      }
      if (focusTarget) {
        focusTarget.focus();
      }
    }
    handleKeyDown(ev) {
      switch (ev.which) {
        case _keyConstants.ESCAPE:
          {
            this.dropdown.actions.close();
            break;
          }
        case _keyConstants.LEFT_ARROW:
        case _keyConstants.UP_ARROW:
          {
            ev.preventDefault();
            let newHighlighted = (0, _groupUtils.advanceSelectableOption)(this.select.results, this.select.highlighted, -1);
            this.select.actions.highlight(newHighlighted, ev);
            this.select.actions.scrollTo(newHighlighted);
            this.focusOption(ev, -1);
            break;
          }
        case _keyConstants.RIGHT_ARROW:
        case _keyConstants.DOWN_ARROW:
          {
            ev.preventDefault();
            let newHighlighted = (0, _groupUtils.advanceSelectableOption)(this.select.results, this.select.highlighted, 1);
            this.select.actions.highlight(newHighlighted, ev);
            this.select.actions.scrollTo(newHighlighted);
            this.focusOption(ev, 1);
            break;
          }
        case _keyConstants.ENTER:
          {
            ev.preventDefault();
            this.select.actions.choose(this.select.highlighted);
            break;
          }
      }
    }
    focusOption(e, direction) {
      let focusTarget = e.target.closest('md-option');
      do {
        if (direction > 0) {
          focusTarget = focusTarget.nextElementSibling;
        } else {
          focusTarget = focusTarget.previousElementSibling;
        }
      } while (focusTarget && !isFocusable(focusTarget));
      if (focusTarget) {
        focusTarget.focus();
      }
    }
    shouldReposition(mutations) {
      let shouldReposition = false;
      shouldReposition = Array.prototype.slice.call(mutations[0].addedNodes).some(node => {
        if (node.classList) {
          return !node.classList.contains('md-ripple') && node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        }
        return false;
      });
      shouldReposition = shouldReposition || Array.prototype.slice.call(mutations[0].removedNodes).some(node => {
        if (node.classList) {
          return !node.classList.contains('md-ripple') && node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        }
        return false;
      });
      return shouldReposition;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "customStyles", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "customStyles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destinationElement", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "destinationElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateIn", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "animateIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateOut", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "animateOut"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focusItem", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "focusItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype)), _class2)) || _class) || _class);
  function isFocusable(el) {
    // is a menu-item, doesn't have tabindex -1 and is not disabled
    return el && el.tagName === 'MD-OPTION' && el.getAttribute('tabindex') !== -1 && el.getAttribute('disabled') === null && el.getAttribute('aria-disabled') !== true;
  }
  var _default = _exports.default = PaperSelectEbdContent;
});
define("ember-paper/templates/components/paper-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EIb43f0g",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"title\",\"content\",\"actions\",\"header\",\"image\",\"media\"],[[28,\"component\",[\"paper-card-title\"],null],[28,\"component\",[\"paper-card-content\"],null],[28,\"component\",[\"paper-card-actions\"],null],[28,\"component\",[\"paper-card-header\"],null],[28,\"component\",[\"paper-card-image\"],null],[28,\"component\",[\"paper-card-media\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-card.hbs"
    }
  });
});
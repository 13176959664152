define("ember-models-table/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.htmlSafe = htmlSafe;
  function htmlSafe([str] /*, hash*/) {
    return Ember.String.htmlSafe(str || '');
  }
  var _default = _exports.default = Ember.Helper.helper(htmlSafe);
});
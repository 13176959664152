define("ember-models-table/templates/components/models-table/grouped-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IKxURazm",
    "block": "{\"symbols\":[\"cell\",\"&default\"],\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"is-equal\",[[24,[\"displayGroupedValueAs\"]],\"column\"],null],[24,[\"useDataGrouping\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"th\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"groupedHeader\"]]],null,{\"statements\":[[0,\"    \"],[7,\"th\",true],[11,\"colspan\",[23,1,[\"colspan\"]]],[11,\"rowspan\",[23,1,[\"rowspan\"]]],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/grouped-header.hbs"
    }
  });
});
define("ember-paper/components/paper-autocomplete/ebd-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "paPp1xp5",
    "block": "{\"symbols\":[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@width\",\"@height\",\"&attrs\",\"&default\"],\"statements\":[[5,\"basic-dropdown-content\",[[12,\"class\",\"md-virtual-repeat-container md-autocomplete-suggestions-container\"],[13,13]],[[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@width\",\"@height\",\"@otherStyles\",\"@animationEnabled\",\"@htmlTag\",\"@shouldReposition\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,0,[\"customStyles\"]],false,\"md-virtual-repeat-container\",[23,0,[\"shouldReposition\"]]]],{\"statements\":[[0,\"\\n\\n  \"],[14,14],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-autocomplete/ebd-content/template.hbs"
    }
  });
});
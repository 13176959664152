define("ember-models-table/templates/components/models-table/cell-content-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tHmFI8lE",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"class\",\"value\"],[\"text\",[24,[\"themeInstance\",\"input\"]],[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell-content-edit.hbs"
    }
  });
});
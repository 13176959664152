define("ember-paper/components/paper-select/search-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ew2hf84R",
    "block": "{\"symbols\":[\"@searchMessage\"],\"statements\":[[7,\"md-content\",true],[10,\"class\",\"ember-power-select-options\"],[10,\"role\",\"listbox\"],[8],[0,\"\\n  \"],[7,\"md-option\",true],[10,\"class\",\"ember-power-select-option ember-power-select-option--no-matches-message\"],[10,\"role\",\"option\"],[8],[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-select/search-message/template.hbs"
    }
  });
});
define("ember-models-table/templates/components/models-table/row-grouping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jYXoJYP6",
    "block": "{\"symbols\":[\"rg\",\"&default\"],\"statements\":[[7,\"td\",true],[11,\"class\",[24,[\"themeInstance\",\"groupingCell\"]]],[11,\"colspan\",[22,\"cellColspan\"]],[8],[0,\"\\n\"],[4,\"with\",[[28,\"hash\",null,[[\"row-group-toggle\"],[[28,\"component\",[[28,\"if\",[[24,[\"groupingRowComponent\"]],[24,[\"groupingRowComponent\"]],[24,[\"themeInstance\",\"components\",\"row-group-toggle\"]]],null]],[[\"groupedValue\",\"groupedLength\",\"currentGroupingPropertyName\",\"displayGroupedValueAs\",\"visibleProcessedColumns\",\"groupedItems\",\"selectedItems\",\"expandedItems\",\"visibleGroupedItems\",\"selectedGroupedItems\",\"expandedGroupedItems\",\"groupIsCollapsed\",\"themeInstance\",\"toggleGroupedRowsSelection\",\"toggleGroupedRowsExpands\",\"toggleGroupedRows\"],[[24,[\"groupedValue\"]],[24,[\"groupedLength\"]],[24,[\"currentGroupingPropertyName\"]],[24,[\"displayGroupedValueAs\"]],[24,[\"visibleProcessedColumns\"]],[24,[\"groupedItems\"]],[24,[\"selectedItems\"]],[24,[\"expandedItems\"]],[24,[\"visibleGroupedItems\"]],[24,[\"selectedGroupedItems\"]],[24,[\"expandedGroupedItems\"]],[24,[\"groupIsCollapsed\"]],[24,[\"themeInstance\"]],[24,[\"toggleGroupedRowsSelection\"]],[24,[\"toggleGroupedRowsExpands\"]],[24,[\"toggleGroupedRows\"]]]]]]]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"      \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[23,1,[\"row-group-toggle\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/row-grouping.hbs"
    }
  });
});
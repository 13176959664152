define("ember-paper/templates/components/paper-progress-linear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+QdKbkwj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"md-container \",[22,\"queryModeClass\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-dashed\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-bar md-bar1\"],[11,\"style\",[22,\"bar1Style\"]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-bar md-bar2\"],[11,\"style\",[22,\"bar2Style\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-progress-linear.hbs"
    }
  });
});
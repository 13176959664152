define("ember-paper/utils/promise-proxies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PromiseObject = _exports.PromiseArray = void 0;
  _exports.promiseArray = promiseArray;
  _exports.promiseObject = promiseObject;
  // See http://emberjs.com/api/data/classes/DS.PromiseArray.html
  const PromiseArray = _exports.PromiseArray = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  // See http://emberjs.com/api/data/classes/DS.PromiseObject.html
  const PromiseObject = _exports.PromiseObject = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  function promiseObject(promise, label) {
    return PromiseObject.create({
      promise: Ember.RSVP.Promise.resolve(promise, label)
    });
  }
  function promiseArray(promise, label) {
    return PromiseArray.create({
      promise: Ember.RSVP.Promise.resolve(promise, label)
    });
  }
});
define("ember-models-table/components/models-table/group-summary-row", ["exports", "ember-models-table/templates/components/models-table/group-summary-row"], function (_exports, _groupSummaryRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class GroupSummaryRow
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _groupSummaryRow.default,
    tagName: 'tr',
    classNames: ['group-summary-row'],
    /**
     * Bound from {{#crossLink "Components.ModelsTable/visibleProcessedColumns:property"}}ModelsTable.visibleProcessedColumns{{/crossLink}}
     *
     * @property visibleProcessedColumns
     * @type ModelsTableColumn[]
     * @default null
     */
    visibleProcessedColumns: null,
    /**
     * @type object[]
     * @property selectedItems
     * @default null
     * @private
     */
    selectedItems: null,
    /**
     * @type object[]
     * @property expandedItems
     * @default null
     * @private
     */
    expandedItems: null,
    /**
     * @type object[]
     * @property groupedItems
     * @default null
     * @private
     */
    groupedItems: null,
    /**
     * @type object[]
     * @property visibleGroupedItems
     * @default null
     * @private
     */
    visibleGroupedItems: null,
    /**
     * @type object[]
     * @property selectedGroupedItems
     * @default null
     */
    selectedGroupedItems: Ember.computed.intersect('selectedItems', 'groupedItems'),
    /**
     * @type object[]
     * @property expandedGroupedItems
     * @default null
     */
    expandedGroupedItems: Ember.computed.intersect('expandedItems', 'groupedItems'),
    /**
     * Bound from {{#crossLink "Components.ModelsTable/themeInstance:property"}}ModelsTable.themeInstance{{/crossLink}}
     *
     * @property themeInstance
     * @type object
     * @default null
     */
    themeInstance: null
  });
});
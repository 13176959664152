define("ember-paper/components/paper-toolbar-tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class PaperToolbarTools
   * @extends Ember.Component
   */
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['md-toolbar-tools']
  });
});
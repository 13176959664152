define("ember-paper/components/paper-menu/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zUpBI6fm",
    "block": "{\"symbols\":[\"&default\",\"@href\",\"@target\",\"@disabled\",\"&attrs\"],\"statements\":[[7,\"md-menu-item\",false],[13,5],[12,\"disabled\",[23,4,[]]],[3,\"on\",[\"mouseenter\",[23,0,[\"handleMouseEnter\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"shouldRenderButton\"]]],null,{\"statements\":[[0,\"    \"],[5,\"paper-button\",[],[[\"@onClick\",\"@href\",\"@target\",\"@disabled\"],[[23,0,[\"handleClick\"]],[23,2,[]],[23,3,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n      \"],[14,1],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-menu/item/template.hbs"
    }
  });
});
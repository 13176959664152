define("ember-google-charts/utils/get-visualization-name", ["exports", "ember-google-charts/utils/visualization-names"], function (_exports, _visualizationNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getVisualizationName;
  function getVisualizationName(type) {
    return _visualizationNames.default[type];
  }
});
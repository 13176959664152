define("ember-models-table/templates/components/models-table/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7Bio0F6A",
    "block": "{\"symbols\":[\"opt\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"option\",true],[11,\"value\",[23,1,[\"value\"]]],[11,\"selected\",[28,\"is-equal\",[[24,[\"value\"]],[23,1,[\"value\"]],[24,[\"type\"]]],null]],[8],[0,\"\\n    \"],[1,[23,1,[\"label\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/select.hbs"
    }
  });
});
define("ember-models-table/components/models-table/themes/ember-semanticui/row-filtering-cell", ["exports", "ember-models-table/components/models-table/themes/semanticui/row-filtering-cell", "ember-models-table/templates/components/models-table/themes/ember-semanticui/row-filtering-cell"], function (_exports, _rowFilteringCell, _rowFilteringCell2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rowFilteringCell.default.extend({
    layout: _rowFilteringCell2.default
  });
});
define("ember-paper/components/paper-toaster", ["exports", "ember-paper/templates/components/paper-toaster"], function (_exports, _paperToaster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _paperToaster.default,
    tagName: '',
    paperToaster: Ember.inject.service(),
    activeToast: Ember.computed.reads('paperToaster.activeToast'),
    onClose(toast) {
      this.get('paperToaster').cancelToast(toast);
    }
  });
});
define("ember-paper/templates/components/paper-grid-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rst01Pan",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"figure\",true],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"footer\"],[[28,\"component\",[\"paper-grid-tile-footer\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-grid-tile.hbs"
    }
  });
});
define("ember-paper/components/paper-checkbox", ["exports", "ember-paper/templates/components/paper-checkbox", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/proxiable-mixin", "ember-invoke-action"], function (_exports, _paperCheckbox, _focusableMixin, _colorMixin, _proxiableMixin, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class PaperCheckbox
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ColorMixin
   * @uses ProxiableMixin
   */
  var _default = _exports.default = Ember.Component.extend(_focusableMixin.default, _colorMixin.default, _proxiableMixin.default, {
    layout: _paperCheckbox.default,
    tagName: 'md-checkbox',
    classNames: ['md-checkbox', 'md-default-theme'],
    classNameBindings: ['isChecked:md-checked', 'indeterminate:md-indeterminate'],
    attributeBindings: ['role:role', 'ariaLabel:aria-label', 'ariaChecked:aria-checked', 'labelId:aria-labelledby'],
    /* FocusableMixin Overrides */
    focusOnlyOnKey: true,
    constants: Ember.inject.service(),
    value: false,
    role: 'checkbox',
    notIndeterminate: Ember.computed.not('indeterminate'),
    isChecked: Ember.computed.and('notIndeterminate', 'value'),
    ariaChecked: Ember.computed('isChecked', 'indeterminate', function () {
      if (this.get('indeterminate')) {
        return 'mixed';
      }
      return this.get('isChecked') ? 'true' : 'false';
    }),
    labelId: Ember.computed('elementId', function () {
      return `${this.elementId}-label`;
    }),
    init() {
      this._super(...arguments);
      (true && !(this.get('onChange') !== undefined) && Ember.assert('{{paper-checkbox}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined));
    },
    click() {
      if (!this.get('disabled')) {
        (0, _emberInvokeAction.invokeAction)(this, 'onChange', !this.get('value'));
      }
      // Prevent bubbling, if specified. If undefined, the event will bubble.
      return this.get('bubbles');
    },
    keyPress(ev) {
      if (ev.which === this.get('constants.KEYCODE.SPACE') || ev.which === this.get('constants.KEYCODE.ENTER')) {
        ev.preventDefault();
        this.click();
      }
    },
    processProxy() {
      (0, _emberInvokeAction.invokeAction)(this, 'onChange', !this.get('value'));
    }
  });
});
define("ember-paper/templates/components/paper-radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HX+hMUwx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"label\",\"radio\"],[[28,\"component\",[[24,[\"labelComponent\"]]],[[\"setAriaLabelledby\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"ariaLabelledby\"]]],null]],null]]]],[28,\"component\",[[24,[\"radioComponent\"]]],[[\"toggle\",\"disabled\",\"groupValue\",\"onChange\",\"parentComponent\",\"shouldRegister\"],[[24,[\"toggle\"]],[24,[\"disabled\"]],[24,[\"groupValue\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null],[23,0,[]],true]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-radio-group.hbs"
    }
  });
});
define("@html-next/vertical-collection/components/vertical-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+ngaXbkH",
    "block": "{\"symbols\":[\"virtualComponent\",\"&inverse\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"virtualComponents\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[1,[28,\"unbound\",[[23,1,[\"upperBound\"]]],null],false],[4,\"if\",[[23,1,[\"isOccludedContent\"]]],null,{\"statements\":[[1,[28,\"unbound\",[[23,1,[\"element\"]]],null],true]],\"parameters\":[]},{\"statements\":[[14,3,[[23,1,[\"content\"]],[23,1,[\"index\"]]]]],\"parameters\":[]}],[1,[28,\"unbound\",[[23,1,[\"lowerBound\"]]],null],false]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"shouldYieldToInverse\"]]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@html-next/vertical-collection/components/vertical-collection/template.hbs"
    }
  });
});
define("ember-vertical-timeline/templates/components/timeline/timeline-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8Y5P0G3w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"image\",\"content\"],[[28,\"component\",[\"timeline/timeline-image\"],[[\"animate\"],[[24,[\"animate\"]]]]],[28,\"component\",[\"timeline/timeline-content\"],[[\"animate\"],[[24,[\"animate\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-vertical-timeline/templates/components/timeline/timeline-block.hbs"
    }
  });
});
define("ember-vertical-timeline/components/timeline/timeline-date", ["exports", "ember-vertical-timeline/templates/components/timeline/timeline-date"], function (_exports, _timelineDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _timelineDate.default,
    tagName: 'span',
    classNames: 'timeline-date'
  });
});